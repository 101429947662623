import { graphql } from 'gatsby'
import * as React from 'react'

import Content from '../components/Content'
import Main from '../components/Main'
import Seo from '../components/seo'

const OtaYhteyttaPage = ({ data, location }) => {
  const { page } = data

  return (
    <Main>
      <Seo />
      <Content content={page.content} />
    </Main>
  )
}

export default OtaYhteyttaPage

export const query = graphql`
  query OtaYhteyttaQuery {
    page: sanityPage(id: { eq: "-127b2ff5-ede8-5dd9-ac76-65c854c6d996" }) {
      ...contentFragment
    }
  }
`
